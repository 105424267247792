@import "src/styles/helpers";

.info {
    display: grid;
    gap: 16px;
}

.title {
  @include h1;
  color: var(--primary-600);

  @include media(mobile) {
    @include h2;
  }
}

.subtitle {
  @include bodyMediumLight;
  color: var(--primary-400);
}

.form {
  margin-top: 48px;

  &__fields {
    display: grid;
    gap: 24px;
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-content: space-between;
    color: var(--primary-600);
    margin-top: 17px;

    .buttonFont {
      gap: 4px !important;

      :global {
        .MuiTypography-root {
          @include buttonFont(true);
          color: var(--primary-600) !important;
          font-family: Inter, sans-serif !important;
        }
      }
    }
  }

  &__button {
    min-width: 100%;
    margin-top: 23px;
  }
}

.linkTextSmall {
  @include linkTextSmall;
}

.error {
  @include bodySmall;
  color: var(--red);
  text-align: center;
  margin-top: 16px;
}
